import React, { useState, useRef, useEffect } from "react";
import "./css/Header.scss";
import './css/Headline.scss'
const Header = () => {




  const [sticky, setSticky] = useState({
    isSticky: false,
    offset: 0,
  });

  const [buttonState, setButtonState] = useState({
    buttonHighlight: false,
    offset: 0,
  });
  const headerRef = useRef(null);
  const buttonRef = useRef(null);

  const handleScroll = (elTopOffset, elHeight, bTop, bHeight) => {

    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }

    if (window.pageYOffset > bTop + bHeight) {
        setButtonState({  buttonHighlight: true, offset: bHeight });
    } else {
        setButtonState({  buttonHighlight: false, offset: 0 });
    }
  };

  useEffect(() => {
    let header = headerRef.current.getBoundingClientRect();
    let button = buttonRef.current.getBoundingClientRect();
    // console.log(header.top, header.height,button.top,button.height,window.pageYOffset)
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height, button.top, button.height);
      //   handleScroll(button.top, button.height, "button");
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  let showForm = () => {
    window.scroll(0,document.querySelector(".feedback-form").offsetTop);
  }
  return (
    <>
      <header
        id="sticky-header"
        className={`${sticky.isSticky ? " sticky" : ""}`}
        ref={headerRef}
      >
        <p>hitech@digit.com</p> <p onClick={showForm} className={`feedback-top-button${buttonState.buttonHighlight ? " highlighted ": ""}`}>Обсудить проект</p>
      </header>

      <div className="headline">
        <h1>Хайтекдиджит</h1>
        <p>
          Разработка программных продуктов <br /> с высокой степенью НИОКР
        </p>
        <button className="feedback-button" ref={buttonRef} onClick={showForm}>
          Обсудить проект
        </button>
      </div>
    </>
  );
};

export default Header;
