import React from "react";
import './css/FeedbackForm.scss'
import emailjs from 'emailjs-com';


function sendEmail(e) {
  e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

  emailjs.sendForm('service_5xblnjt', 'template_467mfw4', e.target, 'user_tM2apzYrWb6uxM9NSo3BP')
    .then((result) => {
        window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    }, (error) => {
        console.log(error.text);
    });
}


const FeedbackForm = () => {
  return (
    <div className="feedback-container">
      <div className="feedback-form">
        <h1>Заполните форму и мы с вами cвяжемся</h1>
        <form action="" onSubmit={sendEmail}>
          <input type="text" placeholder="Как к вам обращаться" name = "from_name"/>
          <input type="text" placeholder="Ваш e-mail"  name = "from_email"/>
          <input type="text" placeholder="Ваш телефон" name="from_phone"/>
          <textarea id="" cols="30" rows="7" placeholder="Расскажите про вашу задачу" name="about_task" ></textarea>
          <button action="submit">Отправить →</button>
          <p className="conditions">Нажимая отправить вы соглашаетесь на обработку персональных данных</p>
        </form>
      </div>
    </div>
  );
};

export default FeedbackForm;
