import React from "react";
import "./css/Development.scss";
const Development = () => {
  return (
    <div className="development-container">
      <div className="development-box">
        <h1>Направления разработки</h1>
        <p>
          Хайтекдиджит существует с 2005 года. <br /> Более 15 лет на рынке
          программных решений
        </p>
        <div className="development-cards">
          <div className="dev-card">
            <div className="card-info">
              <img src="/img/dev-dr/web.png" alt="" />
              <p>Web приложения</p>
            </div>
          </div>
          <div className="dev-card">
            <div className="card-info">
              <img src="/img/dev-dr/desktop.png" alt="" />
              <p>Десктоп приложения</p>
            </div>
          </div>
          <div className="dev-card">
            <div className="card-info">
              <img src="/img/dev-dr/mobile.png" alt="" />
              <p>Мобильные приложения</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Development;
