import React from "react";
import "./css/Advantages.scss";
const Advantages = () => {
  return (
    <div className="advantages">
      <div className="advantage-container">
        <div className="advantage-box">
          <h1>Почему мы</h1>
          <div className="advantage-list">
            <div className="advantage-column">
              <p>100% релизов выполнено в срок</p>
              <p>Работа со скоростью потребностей вашего бизнеса</p>
            </div>
            <div className="advantage-column">
              <p>Сокращаем time-to-market</p>
              <p>Технологическая экспертиза для решения комплексных задач</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
