import logo from './logo.svg';
import React from 'react'
import './App.css';
import Header from './components/Header';
import Headline from './components/Headline';
import Development from './components/Development';
import Advantages from './components/Advantages';
import FeedbackForm from './components/FeedbackForm'
import './components/css/media-breakpoints.scss'
function App() {
  return (
    <div>
    <Header/>
    <Development/>
    <Advantages/>
    <FeedbackForm/>
    

    </div>
  );
}

export default App;
